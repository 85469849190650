<script>
import { helpers } from 'vuelidate/lib/validators'
import ImageUpload from '@/components/general/ImageUpload'
export default {
  name: 'CustomizationSubmenu',
  components: { ImageUpload },
  data () {
    return {
      selectedImage: null,
      selectedImageInitialState: null,
      removeCoverImage: null,
      isSaving: false,
      formData: {
        imageUrl: '',
        removeImage: null
      }
    }
  },
  validations: {
    selectedImage: {
      fileSize: helpers.withParams({
        size: 20971520
      },
      function (image) {
        if (!image || this.selectedImage === this.workspace.coverImageUrl) return true
        return this.isValidBlobSize(image, 20)
      })
    },
    formData: {
      imageUrl: {
        fileSize: helpers.withParams({
          size: 20971520
        },
        function (image) {
          if (!image || this.formData.imageUrl === this.workspace.imageUrl) return true
          return this.isValidBlobSize(image, 20)
        })
      }
    }
  },
  watch: {
    selectedImage () {
      this.$emit('hasChangesToBeSaved', this.selectedImage !== this.selectedImageInitialState)
    }
  },
  computed: {
    workspaceId () {
      return this.selectedWorkspace.lxID
    },
    workspace () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId] || null
    },
    defaultCoverImage () {
      return '/assets/images/workspace-cover-image-default.png'
    },
    noChanges () {
      return this.selectedImage === this.workspace.coverImageUrl && this.formData.imageUrl === this.workspace.imageUrl
    }
  },
  methods: {
    changeImage (image) {
      this.$v.selectedImage.$touch()
      this.selectedImage = image
    },
    removeImage () {
      this.selectedImage = null
      this.removeCoverImage = true
    },
    changeAvatarImage (image) {
      this.$v.formData.imageUrl.$touch()
      this.formData.imageUrl = image
      this.formData.removeImage = null
    },
    removeAvatarImage () {
      this.formData.imageUrl = null
      this.formData.removeImage = true
    },
    saveImage () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = {
          ...this.workspace,
          imageUrl: this.formData.imageUrl,
          removeImage: this.formData.removeImage,
          removeCoverImage: this.removeCoverImage,
          coverImage: this.selectedImage
        }
        this.isSaving = true
        this.$store.dispatch('attemptSaveWorkspaceSettings', { id: this.workspace.id, payload })
          .then(() => {
            this.$store.dispatch('attemptGetWorkspaceById', this.workspaceId)
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t('settings.profile.labels:submit.success')
            })
          })
          .finally(() => {
            this.setup()
            this.synchronizeUser()
            this.updateComponent('availableWorkspaces')
            this.$store.commit('setSelectedWorkspace', this.workspace)
            this.$emit('hasChangesToBeSaved', false)
            this.selectedImageInitialState = this.selectedImage
            this.isSaving = false
          })
      }
    },
    setup () {
      this.$store.dispatch('attemptGetWorkspaceById', this.workspaceId)
        .then(() => {
          this.formData = {
            ...this.formData,
            ...this.workspace,
            resume: !this.formData.resume ? '' : this.formData.resume,
            location: !this.formData.location ? '' : this.formData.location
          }
          const links = []
          this.enumWorkspaceLinkType.forEach(linkType => {
            const hasType = this.formData.links.find(links => links.type.toUpperCase() === linkType)
            if (!hasType && linkType === 'URL') return
            links.push({ type: linkType.toLowerCase(), value: hasType ? hasType.value : '' })
          })
          this.formData.links = links
        })
    }
  },
  created () {
    this.selectedImage = this.workspace.coverImageUrl
    this.selectedImageInitialState = this.workspace.coverImageUrl
    this.setup()
  }
}
</script>
<template>
  <div class="workspace-management--customization-container">
    <div class="settings-profile--body-image">
      <h5 class="settings-profile--subtitle text-left mb-2 h7">
        {{ $t('settings:profile.subtitle.profile.picture') }}
      </h5>
      <image-upload
        :width="200"
        :height="200"
        rounded
        :aspect-ratio="1"
        :imageBackground="formData.imageUrl ? changeThumbToSize(formData.imageUrl, 'avatar') : ''"
        :defaultImageBackground="defaultAvatarUrl('business')"
        @updateImage="changeAvatarImage"
        @removeImage="removeAvatarImage"
        :validation="$v.formData.imageUrl"
        :hide-validation="!$v.formData.imageUrl.$error && isMobile"
      />
    </div>
    <div class="customization--header">
      <h5 class="h7">{{ $t('workspace.setup.step.4:.profile.image.title') }}</h5>
      <p class="body--1">{{ $t('workspace.setup.step.4:.profile.image.paragraph.alternative') }}</p>
    </div>
    <image-upload
      :width="560"
      :defaultImageBackground="defaultCoverImage"
      @updateImage="changeImage"
      :imageBackground="selectedImage || ''"
      @removeImage="removeImage"
      :validation="$v.selectedImage"
      :hide-validation="!$v.selectedImage.$error && isMobile"
    />
    <div class="customization--action">
      <v-btn
        :dark="!noChanges"
        :disabled="noChanges"
        :loading="isSaving"
        @click="saveImage"
        color="#1200D3"
      >
        <b>{{ $t('global:save.changes') }}</b>
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.workspace-management--customization-container {
  text-align: left;
  padding: 24px 20px 70px;
  max-width: 600px;
  margin: 0 auto;
  .customization--header {
    margin-bottom: 8px;
  }
  .relative-container {
    margin-left: 0;
  }
  .customization--action {
    margin-top: 30px;
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    .customization--action {
      border-bottom: 0;
    }
  }
}
</style>
